.subHeader {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 12px;
  padding: 12px;
  font-family: Roboto, sans-serif;
}

.tags {
  grid-row: 3;
  grid-column: span 3;
  gap: 3px;
}

.sourceList {
  position: relative;
  overflow-y: auto;
  padding-inline: 0;
  padding-block: 0;
  min-height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }

  li {
    display: contents;
  }

  li[data-letter]::before {
    position: sticky;
    top: 0;
    content: attr(data-letter);
    display: block;
    background-color: var(--gl_background_gray_2);
    color: var(--gl_text_secondary_disabled);
    padding-block: 0.125rem;
    padding-inline: 0.75rem;
    font: var(--font-12-b);
  }

  .source {
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    display: grid;
    align-items: center;
    grid-template-columns: auto min-content;
    transition: background-color 0.3s;

    & > a {
      min-height: 3.625rem;
    }

    &:hover,
    &:has(> a.active) {
      background: var(--guestsItemlist_hoverActive_background);
    }

    &:has( > a.pending) {
      opacity: 0.5;
    }

    .clientInfo {
      height: auto;
      padding: 16px 12px;
      grid-area: 1 / 1 / auto / span 2;
      display: flex;
      align-items: start;
      flex-direction: column;
      row-gap: 8px;

      & > * {
        display: inline-flex;
        align-items: center;
        color: var(--gl_text_inverse);
        font: var(--font-14-r);
      }

      .fullName {
        font: var(--font-14-b);
        column-gap: 8px;
        margin: 0;
      }

      .phone {
        column-gap: 4px;
        margin: 0;
      }
    }

    .clientDetail {
      grid-area: 1 / 2;
      padding-inline: 0.75rem;
      path {
        fill: var(--gl_icon_constant_secondary);
      }
    }
    .organizationTag {
      display: inline-flex;
      place-items: center;
      color: var(--gl_text_accent);
    }
  }
}
