.dialogContainer,
.dialogContainer::backdrop {
  transition-property: opacity, transform, overlay, display;
  transition-duration: var(--transition-duration);
  transition-behavior: allow-discrete;
  opacity: 0;
}

.dialog-right {
  margin: 0;
  margin-inline-start: auto;
  margin-block-start: var(--header-height);
  height: calc(100% - var(--header-height));
  --x-translate-start: 3rem;
  --y-translate-start: 0;
  --x-translate-end: var(--x-translate-start);
  --y-translate-end: 0;
}
.dialog-top {
  margin: 0;
  margin-inline-start: var(--aside-width);
  margin-block-start: var(--header-height);
  margin-block-end: auto;
  width: calc(100% - var(--aside-width));
  --x-translate-start: 0;
  --y-translate-start: -3rem;
  --x-translate-end: 0;
  --y-translate-end: var(--y-translate-start);
}
.dialog-left {
  margin: 0;
  margin-inline-start: var(--aside-width);
  margin-block-start: var(--header-height);
  height: calc(100% - var(--header-height));
  --x-translate-start: -3rem;
  --y-translate-start: 0;
  --x-translate-end: var(--x-translate-start);
  --y-translate-end: 0;
}
.dialog-bottom {
  margin: 0;
  margin-block-start: auto;
  margin-inline-start: var(--aside-width);
  width: calc(100% - var(--aside-width));
  --x-translate-start: 0;
  --y-translate-start: 3rem;
  --x-translate-end: 0;
  --y-translate-end: var(--y-translate-start);
}
.dialog-center {
  min-width: 380;
  --x-translate-start: 0;
  --y-translate-start: 3rem;
  --x-translate-end: 0;
  --y-translate-end: calc(-1 * var(--y-translate-start));
}

.dialogContainer {
  padding: 0;
  border: none;
  border-radius: 4px;
  overflow: visible;
  transform: translate(var(--x-translate-end), var(--y-translate-end));

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(7.5px);
  }

  &[open],
  &[open]::backdrop {
    opacity: 1;
  }

  &[open] {
    transform: translate(0);
  }
}

@starting-style {
  .dialogContainer[open],
  .dialogContainer[open]::backdrop {
    opacity: 0;
  }

  .dialogContainer,
  .dialogContainer[open] {
    transform: translate(var(--x-translate-start), var(--y-translate-start));
  }
}

.dialogForm {
  background-color: var(--gl_background_3);
  border-radius: 3px;
  border: 1px solid var(--gl_dividerBorder_primary_2);
  width: 100%;
  height: 100%;
}

.closeButton {
  float: inline-end;
  margin-inline: 0.5rem;
  margin-block-start: 0.5rem;
  --icon-color: var(--gl_icon_primary_1);
}
